import React from 'react'
import Slider from 'react-slick';
import {Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../../styles/heroSlider.css';

const HeroSlider = () => {

  const settings = {
    fade: true,
    speed: 2000,
    autoplaySpeed: 3000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  }
  return (
    <Slider {... settings} className="hero__slider">
      <div className="slider__item slider__item-01 mt-0">
        <Container>
          <div className="slider__content">
            <h4 className="text-light mb-3">Car Wash &#8377; 200 Per Day*</h4>
            <h1 className='text-light mb-4'>Book Now and Get 25% Off on Signup.</h1>
            <button className='btn reserve__btn mt-4'>
              <Link to='/cars'>Reserve Now</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-02 mt-0">
        <Container>
          <div className="slider__content">
            <h4 className="text-light mb-3">Car Servicing starting from &#8377; 500/service*</h4>
            <h1 className='text-light mb-4'>Book Now and Get 25% Off on Signup.</h1>
            <button className='btn reserve__btn mt-4'>
              <Link to='/cars'>Reserve Now</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-03 mt-0">
        <Container>
          <div className="slider__content">
            <h4 className="text-light mb-3">Oil Change starting from &#8377; 500*</h4>
            <h1 className='text-light mb-4'>Book Now and Get 25% Off on Signup.</h1>
            <button className='btn reserve__btn mt-4'>
              <Link to='/cars'>Reserve Now</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-04 mt-0">
        <Container>
          <div className="slider__content">
            <h4 className="text-light mb-3">Enhancement &#8377; 10000*</h4>
            <h1 className='text-light mb-4'>Book Now and Get 25% Off on Signup.</h1>
            <button className='btn reserve__btn mt-4'>
              <Link to='/cars'>Reserve Now</Link>
            </button>
          </div>
        </Container>
      </div>
    </Slider>
  )
}

export default HeroSlider
