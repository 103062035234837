import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import "../../styles/servicesPage.css";
import { Box, Text } from "@chakra-ui/react";
const ServicesPage = () => {
  return (
      <div className="services_background">
        <section>
          <Container>
            <Row className="services_heading">Services we provide</Row>
            <Row>
              <Col className="services_cardsAll">
                <Card className="services_card">
                  <i class="ri-roadster-fill cardIconSize"></i>
                  Periodic Service
                </Card>

                <Card className="services_card">
                  <i class="ri-roadster-fill cardIconSize"></i>
                  Car Grooming
                </Card>

                <Card className="services_card">
                  <i class="ri-roadster-fill cardIconSize"></i>
                  AC Service
                </Card>

                <Card className="services_card">
                  <i class="ri-roadster-fill cardIconSize"></i>
                  Car Diagnosis
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="services_cardDetailAll">
                <Card className="services_cardDetails">At-Home Classic</Card>

                <Card className="services_cardDetails">At-Home Complete</Card>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
  );
};

export default ServicesPage;
