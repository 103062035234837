import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import banner from "../../Assets/images/service_banner.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiper.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import axios from "axios";
import img1 from "../../Assets/images/Carousel/1.jpg";
import img2 from "../../Assets/images/Carousel/2.jpg";
import img3 from "../../Assets/images/Carousel/3.jpg";
import img4 from "../../Assets/images/Carousel/4.jpg";
import img5 from "../../Assets/images/Carousel/5.jpg";
import img6 from "../../Assets/images/Carousel/6.jpg";
import img7 from "../../Assets/images/Carousel/7.jpg";
import img8 from "../../Assets/images/Carousel/8.jpeg";
import img9 from "../../Assets/images/Carousel/9.jpeg";
import img10 from "../../Assets/images/Carousel/10.jpg";
import img11 from "../../Assets/images/Carousel/11.jpg";
import img12 from "../../Assets/images/Carousel/12.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
const Products = () => {
  const [categories, setCategories] = useState([]);
  const [SubCategories, setSubCategories] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    carModel: "",
    email: "",
    address: "",
    city: "",
    date: "",
    time: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const datas = [
    {
      id: 1,
      service: "DoorStep Car Wash",
      cars: [
        { name: "Hatch Back", price: "349", time: "25 Mins Taken" },
        { name: "Sedan", price: "400", time: "30 Mins Taken" },
        { name: "Muv", price: "400", time: "35 Mins Taken" },
        { name: "Suv", price: "500", time: "40 Mins Taken" },
      ],
      // car: "Hatch Back",
      count: "Per Car",
      // serviceTime: "25 Mins Taken",
      details: [
        "Exterior Pressure Wash",
        "Vacuum Cleaning",
        "Shampoo Wash",
        "Mats Cleaning",
        "DashBoard Polish",
        "Dust Cleaning",
      ],
    },
    {
      id: 2,
      service: "Interior Deep Cleaning",

      cars: [
        { name: "Hatch Back", price: "799", time: "60 Mins Taken" },
        { name: "Sedan", price: "899", time: "70 Mins Taken" },
        { name: "Muv", price: "999", time: "70 Mins Taken" },
        { name: "Suv", price: "1299", time: "80 Mins Taken" },
      ],
      // car: "Sedan ",
      // rate: "899",
      count: "Per Car",
      // serviceTime: "60 Mins Taken ",
      details: [
        "Seats Drycleaning",
        "Roof,Floor DryCleaning",
        "Exterior Pressure Wash",
        "Vacuum Cleaning",
        "Mats Cleaning",
        "DashBoard Polish",
        "Dust Cleaning",
      ],
    },
    {
      id: 3,
      service: "Rubbing & Polish",

      cars: [
        { name: "Hatch Back", price: "799", time: "60 Mins Taken" },
        { name: "Sedan", price: "899", time: "70 Mins Taken" },
        { name: "Muv", price: "999", time: "70 Mins Taken" },
        { name: "Suv", price: "1299", time: "80 Mins Taken" },
      ],
      // car: "Muv",
      // rate: "899",
      count: "Per Car",
      // serviceTime: "60 Mins Taken",
      details: [
        "Exterior Rubbing With Machine",
        "Engine Cleaning",
        "Complete Polishing",
        "Vacuum Cleaning",
        "Pressure Wash",
        "Tyre Dressing",
      ],
    },
    {
      id: 4,
      service: "Deep All Round Spa Service Package",

      cars: [
        { name: "Hatch Back", price: "1399", time: "120 Mins Taken" },
        { name: "Sedan", price: "1499", time: "130 Mins Taken" },
        { name: "Muv", price: "1699", time: "130 Mins Taken" },
        { name: "Suv", price: "1799", time: "150 Mins Taken" },
      ],
      // car: "Suv",
      // rate: "1499",
      count: "Per Car",
      // serviceTime: "120 Mins Taken",
      details: [
        "Combo Of Interior,Exterior & Wash",
        "Rubbing & Polish",
        "Interior Deep Cleaning",
        "Pressure Wash",
        "Complete Car Cleaning Interior & Exterior",
      ],
    },
  ];

  const getData = async () => {
    try {
      const res = await axios.get(
        "https://app-api.carexpert.org.in/api/categories/all"
      );
      console.log("check", res.data);
      setCategories(res.data);
    } catch (error) {
      console.log("errr", error);
    }
  };

  const getSubCategories = async (categoryId) => {
    try {
      const res = await axios.get(
        `https://app-api.carexpert.org.in/api/subCategories/${categoryId}`
      );
      console.log("subcategory", res.data);
      setSubCategories((prevSubCategories) => ({
        ...prevSubCategories,
        [categoryId]: res.data, // Store subcategories under the category ID
      }));
      setActiveCategory(categoryId);
    } catch (error) {
      console.error("Error fetching subcategories:", error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const images = [
    [img1, img2, img3, img4],
    [img5, img6, img7, img8],
    [img9, img10, img11, img12],
    [img8, img9, img10, img11],
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can handle the form submission
    console.log(formData);

    toast({
      title: "Thank you!",
      description: "Your booking request has been submitted successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    // Reset form data
    setFormData({
      name: "",
      mobile: "",
      carModel: "",
      email: "",
      address: "",
      city: "",
      date: "",
      time: "",
    });

    // Close the modal
    onClose();
  };
  return (
    <>
      <Box height={"100%"} width={"100%"}>
        <Image
          src={banner}
          alt="service banner"
          height={{ base: "40vh", md: "60vh" }}
          width={"100%"}
          objectFit={"cover"}
          filter={"brightness(0.8)"}
        />
      </Box>

      <Flex
        py={12}
        flexDirection={"column"}
        width={{ base: "90%", md: "60%" }}
        margin={"auto"}
        gap={10}
      >
        <Heading
          textAlign={"center"}
          fontSize={{ base: "2rem", md: "2.4rem" }}
          color={"#000D6B"}
        >
          Snappy Doorstep Car Detailing Service{" "}
        </Heading>
        <Flex flexDirection={"column"} gap={2}>
          <Text
            fontSize={"1.1rem"}
            fontWeight={500}
            lineHeight={"1.6"}
            color={"gray.700"}
          >
            Car Detailing Service is a service that is utilized a lot these days
            and it can have various implications to various individuals.
          </Text>
          <Text
            fontSize={"1.1rem"}
            fontWeight={500}
            lineHeight={"1.6"}
            color={"gray.700"}
          >
            Car Detailing is to carefully clean, polish and protect all parts of
            a vehicle start to finish, all around, utilizing specialized tools
            and products that would not ordinarily be used by your typical
            vehicle cleaner. It doesn't include paintwork and body repairs,
            although light cosmetic work may be undertaken, like stone chip
            touch-ups and clear coat wet sanding, depending on the vehicle's
            condition and the detailer.
          </Text>
          <UnorderedList spacing={2}>
            <ListItem fontSize={"1.1rem"} fontWeight={600} color={"#000D6B"}>
              Waterless Washing
            </ListItem>
            <ListItem fontSize={"1.1rem"} fontWeight={600} color={"#000D6B"}>
              Doorstep Service
            </ListItem>
            <ListItem fontSize={"1.1rem"} fontWeight={600} color={"#000D6B"}>
              Less Time Consuming
            </ListItem>
            <ListItem fontSize={"1.1rem"} fontWeight={600} color={"#000D6B"}>
              Superior Products
            </ListItem>
          </UnorderedList>
        </Flex>
      </Flex>

      {/* <Box
          width={"70%"}
          margin={"auto"}
          textAlign={"center"}
          mb={10}
          borderRadius={"lg"}
        >
          <Heading fontWeight={900} color={"#000D6B"}>
            Car Categories
          </Heading>
          <Grid
            pb={6}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
            gap={10}
            mt={20}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {categories.map((ele) => (
              <GridItem
                py={2}
                key={ele._id}
                boxShadow={"lg"}
                borderRadius={"md"}
                overflow={"hidden"}
                cursor={"pointer"}
                _hover={{ boxShadow: "2xl" }}
                onClick={() => getSubCategories(ele._id)}
              >
                <Flex
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                >
                  <Text fontSize={"0.8rem"} fontWeight={700} height={"2vh"}>
                    {ele.tag}
                  </Text>
                  <Image src={ele.categoryImage} alt={ele.id} height={"6vh"} />
  
                  <Text>{ele.variantType}</Text>
                  <Text>{ele.categoryName}</Text>
                </Flex>
              </GridItem>
            ))}
          </Grid>
  
          {activeCategory && SubCategories[activeCategory] && (
            <Box mt={10} p={5} borderWidth={1} borderRadius={"md"}>
              <Heading fontSize={"1.5rem"} color={"#000D6B"} mb={5}>
                Subcategories
              </Heading>
  
              <Grid
                pb={6}
                templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
                gap={10}
                mt={20}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {SubCategories[activeCategory].map((ele) => (
                  <GridItem
                    py={2}
                    key={ele._id}
                    boxShadow={"xl"}
                    borderRadius={"md"}
                    overflow={"hidden"}
                    cursor={"pointer"}
                  >
                    <Flex
                      gap={3}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      py={6}
                    >
                      <Image
                        src={ele.subCategoryImage}
                        alt={ele._id}
                        height={"6vh"}
                      />
  
                      <Text>{ele.subCategoryName}</Text>
                    </Flex>
                  </GridItem>
                ))}
              </Grid>
            </Box>
          )}
        </Box> */}

      <Box
        width={"70%"}
        margin={"auto"}
        textAlign={"center"}
        mb={10}
        borderRadius={"lg"}
      >
        <Heading fontWeight={900} color={"#000D6B"}>
          Car Categories
        </Heading>
        <Accordion allowToggle mt={20}>
          <Grid
            pb={6}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
            gap={10}
            mt={20}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {categories.map((ele) => (
              <AccordionItem key={ele._id} borderTop="none" borderBottom="none">
                <AccordionButton
                  border={"1px solid white"}
                  onClick={() => getSubCategories(ele._id)}
                  p={0}
                  _focus={{ boxShadow: "none" }}
                  _hover={{}} // Remove hover styles
                >
                  <GridItem
                    py={2}
                    boxShadow={"lg"}
                    borderRadius={"md"}
                    overflow={"hidden"}
                    cursor={"pointer"}
                    width="100%" // Ensure GridItem takes the full width of the button
                    _hover={{ boxShadow: "2xl" }}
                  >
                    <Flex
                      gap={2}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Text fontSize={"0.8rem"} fontWeight={700} height={"2vh"}>
                        {ele.tag}
                      </Text>
                      <Image
                        src={ele.categoryImage}
                        alt={ele.id}
                        height={"6vh"}
                      />
                      <Text>{ele.variantType}</Text>
                      <Text>{ele.categoryName}</Text>
                    </Flex>
                  </GridItem>
                </AccordionButton>

                <AccordionPanel
                  pb={0}
                  pt={2}
                  px={0}
                  mt={2}
                  _focus={{ boxShadow: "none" }}
                >
                  <Grid
                    pb={6}
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                      md: "repeat(1, 1fr)",
                    }}
                    gap={10}
                    mt={20}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {SubCategories[ele._id]?.length > 0 ? (
                      SubCategories[ele._id].map((sub) => (
                        <GridItem
                          py={2}
                          key={ele._id}
                          boxShadow={"xl"}
                          borderRadius={"md"}
                          overflow={"hidden"}
                          cursor={"pointer"}
                        >
                          <Flex
                            gap={3}
                            alignItems={"center"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                            py={6}
                          >
                            <Image
                              src={sub.subCategoryImage}
                              alt={sub._id}
                              height={"6vh"}
                            />

                            <Text>{sub.subCategoryName}</Text>
                          </Flex>
                        </GridItem>
                      ))
                    ) : (
                      <Text>No subcategories available</Text>
                    )}
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Grid>
        </Accordion>
      </Box>

      <Swiper
        spaceBetween={50}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
          type: "bullets",
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {images.map((slideImages, index) => (
          <SwiperSlide key={index} style={{ background: "transparent" }}>
            <Flex
              gap={6}
              margin={"auto"}
              width="70%"
              flexDirection={{ base: "column", md: "row" }}
            >
              {slideImages.map((img, imgIndex) => (
                <Box
                  key={imgIndex}
                  width={{ base: "100%", lg: "50%" }}
                  background="#FFFFFF"
                  border="1px solid #d9dcde"
                  rounded="md"
                  py={2}
                  px={2}
                  boxShadow="md"
                  height={300}
                >
                  <Image
                    src={img}
                    alt={`img${imgIndex + 1}`}
                    width="100%"
                    height="auto"
                  />
                </Box>
              ))}
            </Flex>
          </SwiperSlide>
        ))}
      </Swiper>

      <Grid
        pb={6}
        width={"70%"}
        margin={"auto"}
        templateColumns={"repeat(1, 1fr)"}
        gap={4}
        marginTop={20}
      >
        <Heading
          color={"#000D6B"}
          fontSize={{ base: "0.9", md: "2.2rem" }}
          textAlign={"center"}
        >
          Book A Service
        </Heading>
        <Accordion defaultIndex={[0]} allowMultiple>
          {datas.map((service, serviceIndex) => (
            <AccordionItem width="100%" key={serviceIndex}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "blue.100" }}
                      border="3px solid"
                      borderColor="#000D6B"
                      borderRadius="md"
                    >
                      <Box as="span" flex="1" textAlign="left" color="#000D6B">
                        {`${service.service} Service Package`}
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" color="#000D6B" />
                      ) : (
                        <AddIcon fontSize="12px" color="#000D6B" />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <Grid
                      width={{ base: "100%", md: "100%" }}
                      templateColumns={"repeat(1, 1fr)"}
                      gap={4}
                    >
                      <Grid
                        templateColumns={{
                          base: "repeat(1, 1fr)",
                          md: "repeat(4, 1fr)",
                        }}
                        gap={4}
                        width="100%"
                      >
                        {service.cars.map((car, index) => (
                          <GridItem
                            py={2}
                            key={index}
                            boxShadow="lg"
                            borderRadius="md"
                            overflow="hidden"
                          >
                            <Box
                              bg="#000D6B"
                              p={4}
                              color="white"
                              h={"14vh"}
                              textAlign="center"
                            >
                              <Text
                                fontSize={{
                                  base: "1rem",
                                  md: "1.2rem",
                                }}
                                fontWeight={900}
                                mb={2}
                                margin={"auto"}
                              >
                                {car.name}
                              </Text>
                              <Text
                                fontSize={{
                                  base: "0.8rem",
                                  md: "1rem",
                                }}
                                fontWeight={700}
                              >
                                Rs {car.price} - {service.count}
                              </Text>
                            </Box>
                            <Box
                              py={6}
                              bg="gray.50"
                              height={{ base: "40vh", md: "30vh" }}
                            >
                              <UnorderedList
                                spacing={2}
                                fontSize={{
                                  base: "0.8rem",
                                  md: "0.9rem",
                                }}
                                fontWeight={500}
                                color="gray.700"
                              >
                                {service.details.map((detail, i) => (
                                  <ListItem key={i}>{detail}</ListItem>
                                ))}
                              </UnorderedList>
                            </Box>
                            <Flex
                              bg="gray.50"
                              py={1}
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Divider border="1px solid grey" />
                              <Text
                                textAlign="center"
                                fontSize="1rem"
                                fontWeight={800}
                                color="gray.700"
                              >
                                {car.time}
                              </Text>
                              <Button
                                width={{ base: "50%", md: "40%" }}
                                margin="auto"
                                color="white"
                                background="#000D6B"
                                borderRadius="lg"
                                onClick={onOpen}
                              >
                                Book Now
                              </Button>
                            </Flex>
                          </GridItem>
                        ))}
                      </Grid>
                    </Grid>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Grid>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Booking Form</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <FormControl isRequired mb={3}>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                />
              </FormControl>
              <FormControl isRequired mb={3}>
                <FormLabel>Mobile</FormLabel>
                <Input
                  type="tel"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter your mobile number"
                />
              </FormControl>
              <FormControl isRequired mb={3}>
                <FormLabel>Car Model</FormLabel>
                <Input
                  type="text"
                  name="carModel"
                  value={formData.carModel}
                  onChange={handleChange}
                  placeholder="Enter your car model"
                />
              </FormControl>
              <FormControl isRequired mb={3}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email address"
                />
              </FormControl>
              <FormControl isRequired mb={3}>
                <FormLabel>Address</FormLabel>
                <Textarea
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Enter your address"
                />
              </FormControl>
              <FormControl isRequired mb={3}>
                <FormLabel>City</FormLabel>
                <Input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="Enter your city"
                />
              </FormControl>
              <FormControl isRequired mb={3}>
                <FormLabel>Date</FormLabel>
                <Input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired mb={3}>
                <FormLabel>Time</FormLabel>
                <Input
                  type="time"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                />
              </FormControl>
              <ModalFooter>
                <Button colorScheme="blue" type="submit" mr={3}>
                  Submit
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Products;
