import React from "react";
import {
  Box,
  Text,
  Flex,
  VStack,
  Heading,
  UnorderedList,
  ListItem,
  Divider,
  Container,
} from "@chakra-ui/react";

function Refund() {
  return (
    <Box bg="#f9f9f9" minHeight="100vh" py={10}>
      <Container maxW="container.md" bg="white" boxShadow="md" borderRadius="md" p={8}>
        <VStack spacing={6} align="start">
          <Heading as="h1" size="lg" color="#000D6B">
            Refund & Cancellation Policy
          </Heading>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
            This cancellation and refund policy (“Policy”) governs the cancellation of your membership with Car Expert, having its corporate office at E 41 Sector 3 Noida U.P 201301. The company is operated by Goinfinite Digital Services Pvt Ltd using Car Expert as a brand name (“Car Expert”) and of the membership issued by Car Expert, as well as the refund of the fees paid by you towards the purchase of such Membership Plan (“Membership Fees”).
          </Text>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
            In the event that you wish to cancel your membership with Car Expert, you shall be required to intimate the same to Car Expert by calling Car Expert inbound or by sending an email to Car Expert within 14 (fourteen) days of receipt of the package. You shall further be required to return the Package and all documents and other items provided to you along with the Membership by mailing the aforesaid to Car Expert C/O Cancellation Department at E 41 Sector 3 Noida U.P 201301.
          </Text>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
            On receipt of such intimation and Welcome Pack, Car Expert shall verify the details of your membership and the request for cancellation placed by you. On Car Expert's satisfaction that your request is in accordance with this agreement, Car Expert shall carry out the following (collectively, “Cancellation”):
          </Text>

          <UnorderedList spacing={2} fontSize={{ base: "md", md: "lg" }} color="gray.600">
            <ListItem>Discontinue the membership issued to you.</ListItem>
            <ListItem>Issue a refund of the amount paid by you as Membership Fees.</ListItem>
          </UnorderedList>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
            On Cancellation as aforesaid, Car Expert shall intimate you of the fact of such Cancellation and shall inform you of the method of refund of your Membership Fees. Such Cancellation shall be subject to the following terms:
          </Text>

          <UnorderedList spacing={2} fontSize={{ base: "md", md: "lg" }} color="gray.600">
            <ListItem>
              Cancellations shall be considered only if (i) a valid request is made in this regard within 14 (fourteen) days of receipt of the membership, and (ii) the entire Welcome Pack is returned to Car Expert within 14 (fourteen) days of receipt of the cancellation request as aforesaid.
            </ListItem>
            <ListItem>
              No cancellation shall be carried out if you have purchased the Membership under any interest-free easy monthly installment (EMI) option offered by Car Expert.
            </ListItem>
            <ListItem>
              No cancellation shall be carried out if you have in any way availed any of the benefits available under the membership prior to confirmation of cancellation by Car Expert.
            </ListItem>
          </UnorderedList>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
            In the event a request for cancellation is received by Car Expert after dispatch of the Membership's Welcome Pack to you, Car Expert shall deduct an amount of Rs. 1,000 (Rupees one thousand only) or 10% of the membership fee, whichever is higher, from your membership fees and only the balance shall be refunded to you as part of the cancellation.
          </Text>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
            In the event a request for cancellation is received by Car Expert after a positive welcome call to you, Car Expert shall refund up to a maximum of 50% of the membership fee of your membership fees only. All cancellations as approved by the Car Expert's management as per their discretion shall ordinarily be processed within 30 days of receipt by Car Expert Membership's Welcome Pack. In case of no contact post approval, the 30 days may get extended.
          </Text>

          <Divider borderColor="gray.300" />

          <Text fontSize={{ base: "md", md: "lg" }} fontWeight="bold" color="#000D6B">
            Important Refund Note
          </Text>

          <UnorderedList spacing={2} fontSize={{ base: "md", md: "lg" }} color="gray.600">
            <ListItem>All rights to cancellation/Refund have Goinfinite Digital Services Pvt Ltd.</ListItem>
            <ListItem>Cancellation/refund will be processed only upon receipt/return of the purchased membership kit (unused) by Car Expert.</ListItem>
            <ListItem>
              In case there are multiple ongoing dialogues between Car Expert and the member(s), there may be a few instances wherein the time-frame committed for resolving a case may take longer than committed previously.
            </ListItem>
          </UnorderedList>
        </VStack>
      </Container>
    </Box>
  );
}

export default Refund;
