import React from 'react';
import '../../styles/find-car-form.css';
import { Form, FormGroup } from 'reactstrap';

const FindCarForm = () => {
  return (
    <div>
      <Form className='form'>
        
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <FormGroup className='form__group'>
                <input placeholder="Brand" required readOnly/>
            </FormGroup>

            <FormGroup className='form__group'>
                <input placeholder="Car Model" required readOnly/>
            </FormGroup>

            <FormGroup className='select__group'>
                <select>
                    <option value="petrol">Petrol</option>
                    <option value="diesel">Diesel</option>
                    <option value="cng">CNG</option>
                    <option value="EV">EV</option>

                </select>
            </FormGroup>

            <FormGroup className='form__group'>
                <button className='btn find__services-btn'>Find Services</button>
            </FormGroup>

        
        </div>
      </Form>
    </div>
  )
}

export default FindCarForm
