import React from "react";
import { Box, Heading, Text, Container } from "@chakra-ui/react";

const Privacy = () => {
  return (
    <Box bg="#f9f9f9" minHeight="100vh" py={10}>
      <Container
        maxW="container.md"
        bg="white"
        boxShadow="md"
        borderRadius="md"
        p={8}
      >
        <Heading as="h1" fontSize="2xl" color="#000D6B" mb={4}>
          Privacy Policy
        </Heading>

        <Text fontSize="lg" mb={4}>
          Car Expert by Goinfinite Digital Services Pvt Ltd has created this
          privacy statement in order to demonstrate our company commitment to
          your privacy. We assure you that the identity of all who contact us
          through this website is kept confidential. We do not rent, sell, or
          exchange our mailing lists, and we will always strive to be
          responsible in our management and truthful in our advertising.
        </Text>

        <Text fontSize="lg" mb={4}>
          This privacy policy sets out how Car Expert uses and protects any
          information that you give Car Expert when you use this website. The
          Right to Privacy is very important to you and also to us. We believe
          that the information provided by you should only be used to give you
          better service. In concern with this, we have put here a policy to
          protect the information provided by you to us.
        </Text>

        <Heading as="h2" fontSize="xl" color="#000D6B" mb={4} mt={8}>
          Information Usage
        </Heading>

        <Text fontSize="lg" mb={4}>
          Your Information will not be used for any purpose. We take security
          measures to protect your personal information in storage. We shall
          make reasonable efforts to prevent access by unauthorized persons to
          the customer information, such efforts to reflect at least the same
          degree of security that the Company accords its own confidential
          information.
        </Text>

        <Text fontSize="lg" mb={4}>
          We shall ensure that any outside consultant retained by us is made
          aware of, and is bound by this policy. We may change this policy from
          time to time by updating this page. You should check this page from
          time to time to ensure that you are happy with any changes. We are
          committed to ensuring that your privacy is protected.
        </Text>

        <Heading as="h2" fontSize="xl" color="#000D6B" mb={4} mt={8}>
          Security
        </Heading>

        <Text fontSize="lg" mb={4}>
          All payment details which are entered through this Payment Gateway are
          secured and safe while doing the transaction. Car Expert is not liable
          for any failure by the third party making payment to properly protect
          data from being seen on their screen by other persons during the
          online payment process.
        </Text>
      </Container>
    </Box>
  );
};

export default Privacy;
