import React from 'react'
import {Container, Row, Col} from 'reactstrap';
// import '../../styles/about-section.css';
const AboutSection = () => {
  return (
    <div>
      <section>
        <Container>
            <Row>
                <Col lg='6' md='6'>
                    <div className="about__section-content">
                        <h4 className="section__subtitle">About Us</h4>
                        <h2 className="section__title">Welcome to Car Expert</h2>
                        <p className="section__description">Car Expert is a complete car care service platform that brings super convenience to car owners. We understand how hectic it is to take your vehicle to the service centres, which is why we deliver our all-in-one car care solutions straight to your doorstep.

With just a click of a button using our user-friendly app or our website, you can schedule car services from the comfort of your home, office, or anywhere in the city. Our highly experienced automobile technicians will come to your place with advanced equipment and handle the entire process, saving you the time and effort of visiting a car cleaning facility.
                        </p>

                        <div className="about__section-item d-flex align-items-center">
                            <p className="section__description d-flex align-items-center gap-2">
                            <i class="ri-checkbox-circle-line"></i>
                            Car Expert saves your day! Get car care services at your doorstep, hassle-free.
                            </p>

                            <p className="section__description d-flex align-items-center gap-2">
                            <i class="ri-checkbox-circle-line"></i>
                            Convenient services: wash, repair, painting, and more, scheduled citywide.


                            </p>
                        </div>

                        <div className="about__section-item d-flex align-items-center">
                            <p className="section__description d-flex align-items-center gap-2">
                            <i class="ri-checkbox-circle-line"></i>
                            Eco-friendly machines and pH-neutral shampoos for gentle, green car care.
                            </p>

                            <p className="section__description d-flex align-items-center gap-2">
                            <i class="ri-checkbox-circle-line"></i>
                            Free pickup and drop & DoorDtep Services for your convenience, In All Segments.
                            </p>
                        </div>
                    </div>

                </Col>
                <Col lg='6' md='6'>
                    <div className="about__img">
                        <img src='../Assets/images/selectCar.jpg' alt='' className='w-100'/>
                    </div>
                </Col>

            </Row>
        </Container>
      </section>
    </div>
  )
}

export default AboutSection
