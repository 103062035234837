import './App.css';
import Layout from './Components/layout/Layout';


function App() {
  return (
    <Layout/>
  );
}

export default App;
