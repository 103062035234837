import React from 'react'
import HeroSlider from '../UI/HeroSlider'
import Helmet from '../Helmet/Helmet';
import {Container, Row, Col} from 'reactstrap';
import FindCarForm from '../UI/FindCarForm';
import '../../styles/find-car-form.css'
import AboutSection from '../UI/AboutSection';
import ServicesPage from '../UI/ServicesPage';

const Home = () => {
  return (
    <div>
      <Helmet title='Home'>


        <section className="p-0 hero__slider-section">
          <HeroSlider/>

          <div className="hero__form">
            <Container>
              <Row className="form__row">
                <Col lg='4' md='4'>
                  <div className="find__cars-left">
                    <h2>Select Car</h2>
                  </div>
                </Col>

                <Col lg='8' md='8' sm='12'>
                  <FindCarForm/>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <ServicesPage/>
        <AboutSection/>
      </Helmet>
    </div>
  )
}

export default Home
