import React from 'react'
import { Link } from 'react-router-dom';
import {Container, Row, Col, ListGroup, ListGroupItem} from 'reactstrap';
import '../../styles/footer.css';

const quickLinks = [
  {
    path: '/Privacy',
    display: 'Privacy Policy'
  },
  {
    path: '/Term',
    display: 'Term And Condition'
  },
  {
    path: '/Refund',
    display: 'Refunds Policy'
  },

]

const Footer = () => {

  const date = new Date()
  const year = date.getFullYear()
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg='4' md='4' sm='12'>
          <div className='logo footer__logo'>
              <h1>
                <Link to='/home ' className="d-flex align-items-center gap-2">
                <i class="ri-roadster-fill"></i>
                <span>Car Expert</span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content mt-4">
            Car Expert is a complete car care service platform that brings super convenience to car owners. We understand how hectic it is to take your vehicle to the service centres, which is why we deliver our all-in-one car care solutions straight to your doorstep.

With just a click of a button using our user-friendly app or our website, you can schedule car services from the comfort of your home, office, or anywhere in the city. Our highly experienced automobile technicians will come to your place with advanced equipment and handle the entire process, saving you the time and effort of visiting a car cleaning facility.
            </p>
          </Col>

          <Col lg='2' md='4' sm= '6'>
            <div className="mb-4">
              <h5 className='footer__link-title'>Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className='p-0 mt-3 quick__link'><Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg='3' md='4' sm='6'>
          <div className="mb-4">
          <h5 className='footer__link-title mb-4'>Head Office</h5>
          <p className='office__info'>E 41 Sector 3 Noida U.P 201301 , India</p>
          <p className='office__info'>Phone: +91-8383999107</p>
          <p className='office__info'>Email: support@carexpert.org.in</p>
          <p className='office__info'>Office Time: 9am - 7pm</p>


          </div>
          </Col>

          <Col lg='3' md='4'>
          <div className="mb-4">
          <h5 className='footer__link-title'>NewsLetter</h5>
          <p className="section__description mt-4">Subscribe to our Services</p>
          <div className="newsletter">
            <input type="email" placeholder="Email" />
            <span>
            <i class="ri-send-plane-line"></i>
            </span>
          </div>
          </div>
          </Col>

          <Col lg='12' >
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
              <i class="ri-copyright-line"></i> Copyright {year}. All rights reserved. Car Expert
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
